var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"mixed-color-label"},_vm._l((_vm.parsedWords),function(word,index){return _c('span',{key:index},[(word.isPurple && word.link)?_c('a',{class:{
        inherit: _vm.inheritDefaultColor,
        'mixed-color-label__highlight': word.isPurple,
        'mixed-color-label__normal': !word.isPurple
      },attrs:{"href":word.link,"target":_vm.target}},[_vm._v(_vm._s(word.text))]):_c('span',{class:{
        inherit: _vm.inheritDefaultColor,
        'mixed-color-label__highlight': word.isPurple,
        'mixed-color-label__normal': !word.isPurple
      }},[_vm._v(_vm._s(word.text))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }